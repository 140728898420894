import React from "react";
import SuccessLogo from "../../assets/svg/thank.you.inline.svg";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {normalizePath} from "../../utils/get-url-path";
import sanitizeHtml from "sanitize-html";
import sanitizeOptions from "../../helper/sanitizeOptions";

const SuccessMessage = ({successMessageText}) => {
    const sanitizeOpt = sanitizeOptions();
    return (
        <div className={'ReactModal__Overlay--after-open modal-popup thank-you-modal white'}>
            <div className={'modal-inner-wrap'}>
                <div className={'main-content-block'}>
                    <AniLink
                        className={'close-popup'}
                        paintDrip
                        duration={1}
                        hex="#ed5d2b"
                        to={normalizePath('/')}>
                        close
                    </AniLink>
                    <p className={'h5-title'}>Your message was sent</p>
                    <div className={'thank-you-text'}>
                        <p>
                            <SuccessLogo/>
                        </p>
                        <div className={'text'}
                           dangerouslySetInnerHTML={{__html: sanitizeHtml(successMessageText, sanitizeOpt.contentData)}}/>
                    </div>
                    <AniLink
                        className={'transition-link mc-link mc-button-secondary orange left'}
                        paintDrip
                        duration={1}
                        hex="#ed5d2b"
                        to={normalizePath('/')}>

                        <span className="label">
                            back to home
                        </span>
                    </AniLink>
                </div>
            </div>
        </div>
    )
}

export default SuccessMessage;