import React from 'react'
import Layout from '../components/layout';
import {Helmet} from "react-helmet"
import {graphql, useStaticQuery} from "gatsby";
import SuccessMessage from "../components/contact-form/SuccessMessage";

export default () => {
    const thanksPageData = useStaticQuery(graphql`
        {
	        thanksBlock: wpPage(slug: {eq: "thankyou"}) {
	            title
                content
                seo {
                    title
                    metaDesc
                    canonical
                }
                additionalMetaTag {
                    keywords
                    ogDescription
                    ogTitle
                    ogType
                    ogImage {
                        mediaItemUrl
                    }
                    twitterImage{
                        mediaItemUrl
                    }
                    twitterTitle
                    twitterDescription
                }
            }
        }
	`)
    const {thanksBlock} = thanksPageData;
    const {content, seo, additionalMetaTag, title} = thanksBlock;
    // Set metadata
    const seoData = {...{seo}, additionalMetaTag, title};

    return (
        <Layout
            seoData={seoData}>
            <div property={'disablePreFooter'}>
                <Helmet
                    bodyAttributes={{
                        class: 'dark-background main-without-margin thank-you-modal__open'
                    }}
                />
                {!!content && <SuccessMessage
                    successMessageText={content}
                />}
            </div>
        </Layout>
    )
}




